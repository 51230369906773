
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































































.hero,
[class*='hero--'] {
  // prettier-ignore
  @include fluid(gap,(xxs: 20px, xxl: 40px));

  display: flex;
  flex-direction: column;

  &.hero-as-backlink {
    padding-top: 4rem;
  }
}

.hero__picture {
  position: relative;
  clip-path: ellipse(20% 40%);
  transform: rotate(-10deg) scale(1);

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (85 / 144) * 100%;
  }

  img {
    @include image-fit;

    transform: rotate(10deg) scale(1.2);
  }
}

.hero__title {
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-style: normal;
  }
}

.hero__title__text {
  @extend %fw-light;

  text-align: center;

  ::v-deep strong {
    font-style: normal;
    font-weight: 300;
  }
}

.hero__title__logo {
  // prettier-ignore
  @include fluid(width,(xxs: 155px, xxl: 415px));

  fill: $c-green;
}

.hero__text,
.hero__lead {
  margin-right: col(2, 18);
  margin-left: col(2, 18);
  text-align: center;
}

.hero__lead {
  color: $c-green;
}

.hero__text {
  @include mq(m) {
    margin-right: col(4, 18);
    margin-left: col(4, 18);
  }
}

.card__link {
  // prettier-ignore
  @include fluid(font-size,(xxs: 14px, xxl: 16px));

  // prettier-ignore
  // @include fluid(letter-spacing,(xxs: 2.2px, xxl: 3.2px));
  position: relative;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;

  &::before {
    content: '';
    width: 0;
    height: 1px;
    background: $c-brown;
    transition: width 0.25s;
  }

  svg {
    width: 0.7rem;
    margin-right: 2.5rem;
    fill: $c-brown;
    transition: transform 0.15s;
  }

  .card:hover & {
    &::before {
      width: 5rem;
    }
  }
}

.hero__backlink {
  position: absolute;
  top: 8rem;
  left: 0;
  color: $c-brown;
  text-decoration: none;

  span {
    position: relative;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;

    &::before {
      position: absolute;
      right: 0;
      bottom: -0.4rem;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $c-black;
    }
  }

  svg {
    width: 0.7rem;
    margin-right: 0.7rem;
    fill: $c-brown;
    transition: transform 0.15s;
    transform: rotate(180deg);
  }

  @include mq(m) {
    top: 14rem;
  }
}
