
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































.gallery,
[class*='gallery--'] {
  &:focus {
    outline: none;
  }
}

.gallery__title {
  @extend %fw-normal;

  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 10px, xxl: 40px));

  // prettier-ignore
  @include fluid(font-size,(xxs: 24px, xxl: 64px));

  // prettier-ignore
  @include fluid(line-height,(xxs: 28px, xxl: 70px));

  font-family: $ff-alt;
}

.gallery__list {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 10px, xxl: 40px));

  position: relative;
  overflow: hidden;
  max-height: 70vh;

  &:focus {
    outline: none;
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (9 / 13) * 100%;
  }

  ::v-deep .flickity-viewport {
    @include get-all-space;
  }

  ::v-deep .flickity-slider {
    height: 100%;
  }
}

.gallery__list__item {
  @include image-fit;

  cursor: grab;
}

.gallery__nav {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;

  button {
    cursor: pointer;
  }

  @include mq($until: s) {
    flex-wrap: wrap;
  }
}

.gallery__nav__arrow {
  // prettier-ignore
  @include fluid(width,(xxs: 50px, xxl: 60px));

  // prettier-ignore
  @include fluid(height,(xxs: 50px, xxl: 60px));

  order: 2;
  padding: 0;
  background: transparent;
  border: 1px solid $c-skin;
  transform: rotate(180deg);
  transition: border-color 0.15s;

  svg {
    fill: $c-brown;
  }

  &.next {
    transform: rotate(0);
  }

  &:hover {
    border-color: $c-brown;
  }

  @include mq(s) {
    order: 1;
  }
}

.gallery__nav__thumbs {
  display: flex;
  justify-content: center;
  order: 1;
  gap: 3rem;

  @include mq($until: s) {
    width: 100%;
  }
}

.gallery__nav__thumbs__item {
  width: auto;
  padding: 0;
  background: transparent;
  border: 0;
  opacity: 0.2;
  transition: opacity 0.15s;

  &.is-active {
    opacity: 1;
  }

  img {
    width: auto;
    height: auto;
    max-height: 9rem;
  }
}
