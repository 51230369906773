
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































.article,
[class*='article--'] {
  // prettier-ignore
  @include fluid(padding-bottom,(xxs: 60px, xxl: 160px));

  overflow: hidden;
}

.article__hero {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 30px, xxl: 80px));

  ::v-deep .hero__picture {
    transform: none !important;
    clip-path: none !important;

    img {
      transform: none !important;
    }
  }

  ::v-deep .hero__title__logo {
    display: none;
  }

  ::v-deep .hero__lead {
    color: $c-brown;
  }

  ::v-deep .hero__lead,
  ::v-deep .hero__text {
    text-align: left;
  }

  ::v-deep .hero__hr {
    // prettier-ignore
    @include fluid(margin-top,(xxs: 10px, xxl: 40px));
  }

  @include mq(m) {
    ::v-deep .hero__text,
    ::v-deep .hero__lead {
      margin-right: col(2, 18);
      margin-left: col(2, 18);
    }
  }
}

.article__hr {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 30px, xxl: 80px));
}

.article__content__item {
  &:not(:last-child) {
    // prettier-ignore
    @include fluid(margin-bottom,(xxs: 30px, xxl: 80px));
  }
}

.article__content__picture {
  img {
    width: 100%;
  }
}

.article__content__wysiwyg {
  @include mq(m) {
    padding: 0 col(2, 18);
  }
}
